import React from "react";

export const headline1 = (props) => {
  return <h1 className="h2">{props.children}</h1>;
};

export const headline2 = (props) => {
  return <h2 className="h3">{props.children}</h2>;
};

export const headline3 = (props) => {
  return <h3 className="h4">{props.children}</h3>;
};

export const headline4 = (props) => {
  return <h4 className="h5">{props.children}</h4>;
};
