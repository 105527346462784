import React from "react";
import PropTypes from "prop-types";

export const Quote = ({ right, background, children, position, person }) => {
  return (
    <div
      className={`float-teaser
    text-color-white vertical-offset mb-4 mb-md-5 reveal ${
      right ? "float-teaser-right" : "float-teaser-left"
    }`}
      data-offset="50%"
    >
      <div
        className="before"
        style={{
          backgroundColor: background,
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className={`col-lg-8 ${right ? "offset-lg-4" : ""}`}>
            <div
              className="float-teaser-body pl-3 pr-3 pl-md-4 py-4"
              style={{ backgroundColor: background, color: "white" }}
            >
              <p>„{children}“</p>
              <p className="text-sans text-size-5">
                {person} <br /> {position}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Quote.defaultTypes = {
  background: "#333",
};

Quote.propTypes = {
  children: PropTypes.string,
  right: PropTypes.string,
  position: PropTypes.string,
  person: PropTypes.string,
  background: PropTypes.string,
};
