import React from "react";
import PropTypes from "prop-types";

export const Body = ({ children }) => {
  return (
    <div className="container mb-5 reveal">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-4 mb-3">{children}</div>
      </div>
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
};
