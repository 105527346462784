import React from "react";
import PropTypes from "prop-types";

export const Image = ({ children }) => {
  return (
    <div className="content-container  body-center">
      <div className="row mb-5">
        <div className="image-full-width col-12  d-flex flex-column align-items-center">
          {children}
        </div>
      </div>
    </div>
  );
};

Image.propTypes = {
  children: PropTypes.object,
};
