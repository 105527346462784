import React from "react";
import PropTypes from "prop-types";

export const Center = ({ children }) => {
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12 col-md-8 offset-md-2 text-center d-flex flex-column align-items-center">
          {children}
        </div>
      </div>
    </div>
  );
};

Center.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
};
