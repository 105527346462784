import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export const Technology = ({ left, background, children }) => {
  return (
    <div
      className={`float-teaser
    text-color-white vertical-offset mb-4 mb-md-5 reveal ${
      left ? "float-teaser-left" : "float-teaser-right"
    }`}
      data-offset="50%"
    >
      <div
        className="before"
        style={{
          backgroundColor: background,
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className={`col-lg-8 ${left ? "" : "offset-lg-4"}`}>
            <div
              className="float-teaser-body pl-3 pr-3 pl-md-4 py-4 case-item-detail-technology"
              style={{ backgroundColor: background, color: "white" }}
            >
              <h3 className="h4">
                <FormattedMessage id="technology.text" />
              </h3>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Technology.defaultTypes = {
  background: "#333",
};

Technology.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  left: PropTypes.string,
  background: PropTypes.string,
};
